import React, {useState} from "react";
import Stars from "./Stars";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"Mike M. Louisville, KY",
            date:"Remove Trees",
            text:"I had a good sized tree in my yard that I wanted removed and the stump ground. I hire Sanchez Tree Service to do the job. The team showed up on time and did an excellent job. They were friendly and had the tree on the ground in no time. They cleaned up well after they were finished. The price was very reasonable as well. I recommend them highly!.",
            stars:5
        },
        {
            name:"Thomas M. Louisville, KY",
            date:"Remove Trees",
            text:"Sanchez Tree Service removed a dead tree and grind down the stump for a very good rate. We scheduled a time for them to be out to do the work and they were on time however they had to come back the following week to grind down the stump. The team was friendly and completely cleaned the area once completed. I will recommend Sanchez Tree Service for the price but would like to have everything done at once so I don't have to schedule multiple days",
            stars:5
        },
        {
            name:"Mark H. New Castle, KY",
            date:"Remove Trees",
            text:"This outfit is the best. They took out two 150+ year old maple trees. They ground up the limbs, then cut up the branches and trunks and loaded them on a truck and carted them away. Then they swept and raked the yard, street and sidewalks. They surprised me by getting out the leaf blower and cleaned the sawdust off the driveway and the leaves off the front porch. Two days later they came back and ground the stump. I am very satisfied and the price was not bad , either. Very professional work. Thank you .",
            stars:5
        },
        {
            name:"Kevin M. Louisville, KY",
            date:"Trim or Remove Shrubs",
            text:"They were professional, timely, prompt. Couldn't have asked for more. They were great.",
            stars:5
        },
        {
            name:"Mark P.",
            date:"Trim or Remove Trees and Shrubs",
            text:"Over all good work.",
            stars:5
        },

    ]

    return(
        <section className="w-full py-20">

                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] h-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium">{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews